<template>
  <b-card
    v-if="statsData"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>{{ t('Statistics') }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0" />
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item,index) in statsData"
          :key="index"
          xl="3"
          sm="6"
          class="py-1"
          :class="item.customClass"
        >
          <a
            href="javascript:void(0)"
            class="text-decoration-none text-secondary"
          >
            <b-media
              no-body
              @click="$router.push({name:'leads/', params: { period : item.redirect_type}})"
            >
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="color"
                >
                  <feather-icon
                    size="24"
                    :icon="icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.count_value }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.title }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </a>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    countData: [],
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      statsData: '',
      customClass: 'mb-2 mb-xl-0',
      icon: 'UserIcon',
      color: 'light-danger',
    }
  },
  watch: {
    countData(val) {
      this.statsData = val
    },
  },
}
</script>
