<template>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <b-button
        variant="primary"
        @click="$router.push('/leads/add-lead')"
      >
        <feather-icon icon="PlusIcon" />
        {{ t('Add Lead') }}
      </b-button>
    </div>
    <Statistics :count-data="countData" />
    <FunnelReport />
    <PipeLineReport />
    <WeeklyReport />
    <QuickLinks />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import Statistics from './Statistics.vue'
import PipeLineReport from './PipeLineReport.vue'
import WeeklyReport from './WeeklyReport.vue'
import QuickLinks from './QuickLinks.vue'
import store from '../../../store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'
import FunnelReport from './FunnelReport.vue'

export default {
  components: {
    Statistics,
    BButton,
    QuickLinks,
    PipeLineReport,
    WeeklyReport,
    FunnelReport,
  },
  data() {
    return {
      TodayLeadCount: 0,
      TotalLeadCount: 0,
      searchParams: {},
      countData: [],
      LeadData: {},
      setInterval: '',
    }
  },
  created() {
    this.GetCountSummary()
  },
  mounted() {
    this.setInterval = window.setInterval(() => {
      this.GetCountSummary()
    }, 60000)
  },
  destroyed() {
    window.clearInterval(this.setInterval)
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  methods: {
    async GetCountSummary() {
      showLoader()
      await store.dispatch('DashboardApi/GetCountSummary', this.searchParams).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.countData = response.data.data
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
