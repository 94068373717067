<template>
  <b-card title="Activity Report">
    <b-row>
      <b-col cols="3" class="reportType">
        <b-form-group>
          <b-form-radio-group id="radio-slots" v-model="selected" :options="options">
            <b-form-radio value="monthly">
              Monthly</b-form-radio>
            <b-form-radio value="weekly">
              Weekly </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <v-select v-model="form.year" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="Year" :placeholder="t('Year')" :options="Years" @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="selected == 'weekly'" cols="2">
        <b-form-group>
          <v-select v-model="form.month" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text" :reduce="op => op.value" placeholder="Select Month" :options="months"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="selected == 'weekly'" cols="2">
        <b-form-group>
          <v-select v-model="form.week" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text" :reduce="op => op.value" placeholder="Select Week" :options="weeks"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="selected == 'monthly'" cols="2">
        <b-form-group>
          <v-select v-model="form.taskType" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text" :reduce="op => op.value" placeholder="Task Type" :options="taskTypes"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <v-select v-model="form.user" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username" :reduce="op => op.companyuserid" placeholder="Select User" :options="userData"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="selected == 'monthly'" cols="2">
        <b-button variant="primary" v-on:click="downloadExcel">
          <feather-icon icon="FileIcon" />
          {{ t('Export') }}
        </b-button>
      </b-col>
      <b-col v-if="selected == 'weekly'" cols="12" class="text-right mb-1">
        <b-button variant="primary" v-on:click="downloadExcel">
          <feather-icon icon="FileIcon" />
          {{ t('Export') }}
        </b-button>
      </b-col>
    </b-row>
    <b-table-simple id="activityTable" v-if="selected == 'monthly'" hover caption-top responsive
      class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="stages">Month</b-th>
          <b-th>Week1</b-th>
          <b-th>Week2</b-th>
          <b-th>Week3</b-th>
          <b-th>Week4</b-th>
          <b-th>Week5</b-th>
          <b-th>Total</b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </th>
          <th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </th>
          <th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </th>
          <th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </th>
          <th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </th>
          <b-th>
            <div class="row">
              <div class="col">Prospact</div>
              <div class="col">Existing</div>
              <div class="col"> Total </div>
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(Monthly, index) in MonthlyData" :key="index">
          <b-th class="stages">{{ index }}</b-th>
          <b-td v-for="(value, i) in Monthly" :key="i"
            :variant="(i == 'total') ? '' : (value >= 10) ? 'success' : (value > 2 && value < 10) ? 'warning' : ''">
              <div class="row">
                <div class="col" v-for="(innerData, j) in value" :key="j"
                  :variant="(j == 'total') ? '' : (innerData >= 10) ? 'success' : (innerData > 2 && innerData < 10) ? 'warning' : ''">{{
                  innerData }}
                </div>
              </div>
            </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple id="activityTableWeekly" v-else-if="selected == 'weekly'" hover caption-top responsive class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>Date</b-th>
          <b-th v-for="(value, i) in WeeklyData.weekDates" :key="i">{{ value }}</b-th>
          <b-th>Total</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th class="title-row" colspan="9">Visit</b-th>
        </b-tr>
        <b-tr>
          <b-th>Prospact</b-th>
          <b-td v-for="(value, i) in WeeklyData.prospactVisit" :key="i">{{ value }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Existing</b-th>
          <b-td v-for="(value, i) in WeeklyData.existingVisit" :key="i">{{ value }}</b-td>
        </b-tr>
        <b-tr>
          <b-th class="title-row" colspan="9">Phone Call</b-th>
        </b-tr>
        <b-tr>
          <b-th>Prospact</b-th>
          <b-td v-for="(value, i) in WeeklyData.prospactCall" :key="i">{{ value }}</b-td>
        </b-tr>
        <b-tr>
          <b-th>Existing</b-th>
          <b-td v-for="(value, i) in WeeklyData.existingCall" :key="i">{{ value }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <template #code>
      {{ codeSimple }}
    </template>
  </b-card>
</template>
<script>
import vSelect from 'vue-select'
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import store from '../../../store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    vSelect,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      selected: 'monthly',
      options: [],
      MonthlyData: [],
      WeeklyData: [],
      Years: [],
      months: [],
      weeks: [
        { value: 1, text: 'Week1' },
        { value: 2, text: 'Week2' },
        { value: 3, text: 'Week3' },
        { value: 4, text: 'Week4' },
        { value: 5, text: 'Week5' },
      ],
      leadTypes: ['Prospacts', 'Existing'],
      taskTypes: [],
      userData: [],
      form: {
        year: null,
        month: null,
        week: null,
        leadType: null,
        taskType: null,
        user: JSON.parse(localStorage.getItem('userData')).companyuserid,
      },
    }
  },
  created() {
    this.filterData()
  },
  mounted() {
    this.setInterval = window.setInterval(() => {
      this.filterData()
    }, 500000)
  },
  methods: {
    downloadExcel() {
      const htmltable = document.getElementById('activityTable')
      const html = htmltable.outerHTML
      window.open(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8, ${encodeURIComponent(html)}`)
    },
    getTotal(weeks) {
      let total = 0
      Array.prototype.forEach.call(weeks, (value, i) => {
        if (i > 0) {
          total += value
        }
      })
      return total
    },
    filterData() {
      showLoader()
      store.dispatch('DashboardApi/GetLeadReport', this.form).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.MonthlyData = response.data.data.monthlyReport
          this.Years = response.data.data.yearsArray
          this.months = response.data.data.monthArray
          this.WeeklyData = response.data.data.weekelyReport
          this.taskTypes = response.data.data.subjectList
          this.userData = response.data.data.userList
          if (!this.form.year) {
            this.form.year = this.Years.shift()
          }
          if (!this.form.month) {
            this.form.month = this.months.shift()
          }
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>
<style lang="scss">
#activityTable {
  text-align: center;
}
.stages {
  text-align: left;
}
.innerData td {
  border: none !important;
}
.title-row {
  background-color: rgba(234, 84, 85, 0.12);
}
.reportType {
  margin-top: 9px;
}
</style>
