<template>
  <b-card title="Pipeline Report">
    <b-row>
      <b-col cols="3" class="reportType">
        <b-form-group>
          <b-form-radio-group id="radio-pipeline" v-model="selectedType" :options="options">
            <b-form-radio value="monthly">
              Monthly </b-form-radio>
            <b-form-radio value="weekly">
              Weekly </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <v-select v-model="form.year" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="Year" :placeholder="t('Year')" :options="Years" @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col v-if="selectedType == 'weekly'" cols="2">
        <b-form-group>
          <v-select v-model="form.month" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text" :reduce="op => op.value" placeholder="Select Month" :options="months"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <v-select v-model="form.user" class="p-0 border-0" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="username" :reduce="op => op.companyuserid" placeholder="Select User" :options="userData"
            @input="filterData($event)" />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-button variant="primary" v-on:click="downloadPipeLineExcel">
          <feather-icon icon="FileIcon" />
          {{ t('Export') }}
        </b-button>
      </b-col>
    </b-row>
    <b-table-simple id="pipeLineTable" v-if="selectedType == 'monthly'" hover caption-top responsive
      class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="stages">Stage</b-th>
          <b-th>Jan</b-th>
          <b-th>Feb</b-th>
          <b-th>Mar</b-th>
          <b-th>Apr</b-th>
          <b-th>May</b-th>
          <b-th>Jun</b-th>
          <b-th>Jul</b-th>
          <b-th>Aug</b-th>
          <b-th>Sep</b-th>
          <b-th>Oct</b-th>
          <b-th>Nov</b-th>
          <b-th>Dec</b-th>
          <b-th>Total</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(Monthly, index) in MonthlyPipeLineData" :key="index">
          <b-th class="stages">{{ index }}</b-th>
          <b-td v-for="(value, i) in Monthly" :key="i"
            :variant="(i == 'total') ? '' : (value >= 10) ? 'success' : (value > 2 && value < 10) ? 'warning' : ''">{{
              value }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple id="pipeLineTable" v-else-if="selectedType == 'weekly'" hover caption-top responsive class="rounded-bottom mb-0">
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="stages">Stage</b-th>
          <b-th>Week 1</b-th>
          <b-th>Week 2</b-th>
          <b-th>Week 3</b-th>
          <b-th>Week 4</b-th>
          <b-th>Week 5</b-th>
          <b-th>Total</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(Weekly, index) in WeeklyPipeLineData" :key="index">
          <b-th class="stages">{{ index }}</b-th>
          <b-td v-for="(value, i) in Weekly" :key="i"
            :variant="(i == 'total') ? '' : (value >= 10) ? 'success' : (value > 2 && value < 10) ? 'warning' : ''">{{
              value }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <template #code>
      {{ codeSimple }}
    </template>
  </b-card>
</template>
<script>
import vSelect from 'vue-select'
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import store from '../../../store'
import constants from '@/constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    vSelect,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      selectedType: 'monthly',
      options: [],
      MonthlyPipeLineData: [],
      WeeklyPipeLineData: [],
      Years: [],
      months: [],
      weeks: [
        { value: 1, text: 'Week1' },
        { value: 2, text: 'Week2' },
        { value: 3, text: 'Week3' },
        { value: 4, text: 'Week4' },
        { value: 5, text: 'Week5' },
      ],
      leadTypes: ['Prospacts', 'Existing'],
      taskTypes: [],
      userData: [],
      form: {
        year: null,
        month: null,
        week: null,
        leadType: null,
        taskType: null,
        user: JSON.parse(localStorage.getItem('userData')).companyuserid,
      },
    }
  },
  created() {
    this.filterData()
  },
  mounted() {
    this.setInterval = window.setInterval(() => {
      this.filterData()
    }, 500000)
  },
  methods: {
    downloadPipeLineExcel() {
      const htmltable = document.getElementById('pipeLineTable')
      const html = htmltable.outerHTML
      window.open(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8, ${encodeURIComponent(html)}`)
    },
    getTotal(weeks) {
      let total = 0
      Array.prototype.forEach.call(weeks, (value, i) => {
        if (i > 0) {
          total += value
        }
      })
      return total
    },
    filterData() {
      showLoader()
      store.dispatch('DashboardApi/GetPipeLineReport', this.form).then(async response => {
        if (response.data.code === constants.SUCCESS) {
          this.MonthlyPipeLineData = response.data.data.monthlyReport
          this.Years = response.data.data.yearsArray
          this.months = response.data.data.monthArray
          this.WeeklyPipeLineData = response.data.data.weekelyReport
          this.userData = response.data.data.userList
          if (!this.form.year) {
            this.form.year = this.Years.shift()
          }
          if (!this.form.month) {
            this.form.month = this.months.shift()
          }
          hideLoader()
        }
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Something went wrong',
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>
<style lang="scss">
table {
  text-align: center;
}
.stages {
  text-align: left;
}
.title-row {
  background-color: rgba(234, 84, 85, 0.12);
}
.reportType {
  margin-top: 9px;
}
</style>
